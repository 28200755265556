/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    func,
    shape, string,
} from 'prop-types';
import ResponsiveImage from '../../GraphqlComponents/GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import LinkOrATag from '../../GraphqlComponents/GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import replaceSpecialChar from '../../../helpers/replaceSpecialChar';
// strips "font-family:" and ";" from value
const parseFont = (font) => font?.match(/:\s(.*?);/, '')?.[1] || null;
const themes = {
    imageBorderRadius: {
        default: {
            borderRadius: '0px  ',
        },
        circular: {
            borderRadius: '50%',
            border: '1px solid #D5D5D5',
            padding: 'clamp(5px,1vw,10px)',
        },
    },
    mobilePresentationStyle: {
        scrollbar: {
            aspectRatio: '1/1',
        },
    },
    mobilelinkPanelStyle: {
        scrollbar: {
            width: '90px',
        },
    },

};

const useStyles = makeStyles((theme) => ({
    image: ({
        presentationStyle, insetBorder, mobilePresentationStyle, sectionStyling,
    }) => ({
        width: '100%',
        maxHeight: '330px',
        height: 'auto',
        aspectRatio: '1/1',
        borderRadius: themes.imageBorderRadius?.[presentationStyle]?.borderRadius,
        padding: insetBorder && sectionStyling?.insetBorder?.padding ? sectionStyling?.insetBorder?.padding : themes.imageBorderRadius?.[presentationStyle]?.padding,
        border: insetBorder && sectionStyling?.insetBorder?.border ? sectionStyling?.insetBorder?.border : themes.imageBorderRadius?.[presentationStyle]?.border,
        [theme.breakpoints.down(1280)]: {
            padding: insetBorder && sectionStyling?.insetBorder?.padding ? sectionStyling?.insetBorder?.padding : 'clamp(5px, 0.7vw, 10px)',
        },
        [theme.breakpoints.down(768)]: {
            width: '100%',
            aspectRatio: themes.mobilePresentationStyle?.[mobilePresentationStyle]?.aspectRatio,
            border: insetBorder && sectionStyling?.insetBorder?.border ? sectionStyling?.insetBorder?.border :  'none',
            padding: insetBorder && sectionStyling?.insetBorder?.padding ? sectionStyling?.insetBorder?.padding  : '0px',
        },
    }),
    panelItem: ({ styling }) => ({
        textDecoration: 'none',
        color: styling?.title_font_color?.color || '#D5D5D5',
        textAlign: 'center',
        fontFamily: `${parseFont(styling.title_font) || 'Crimson, Garamond, Times, serif'}`,
        fontSize: theme.palette?.text?.fontSize20 || '20px',
        textWrap: 'balance',
        [theme.breakpoints.down(1280)]: {
            fontSize: 'clamp(0.9rem, 1.7vw, 2rem)',
        },
        [theme.breakpoints.down(768)]: {
            fontSize: theme.palette?.text?.fontSize14 || '14px',
        },
    }),
    linkPanel: ({ mobilePresentationStyle, sectionStyling }) => ({
        textDecoration: 'none',
        [theme.breakpoints.down(767)]: {
            width: sectionStyling?.width || themes.mobilelinkPanelStyle?.[mobilePresentationStyle]?.width,
            minHeight: themes.mobilelinkPanelStyle?.[mobilePresentationStyle]?.minHeight,
        },
    }),
    menuLinksIcons: ({ sectionStyling }) => ({
        width: sectionStyling?.width,
    }),
}));

const NupV3Panel = ({
    panelItem, styling, sectionStyling, handleCallback, dimensions,
}) => {
    const insetBorder = styling?.has_inset_border;
    const presentationStyle = styling?.presentation_style;
    const mobilePresentationStyle = styling?.mobile_presentation;
    const paneltitle = panelItem?.linking?.link;
    const classes = useStyles({
        presentationStyle, insetBorder, mobilePresentationStyle, styling, sectionStyling, panelDimension: panelItem?.image?.dimension,
    });

    return (
        <>
            <LinkOrATag
                isTrackEventEnabled
                trackingEventAction={panelItem?.linking?.tracking_event_action || ''}
                trackingEventCategory={panelItem?.linking?.tracking_event_category || ''}
                trackingEventLabel={panelItem?.linking?.tracking_event_label || ''}
                handleCallback={handleCallback}
                className={`${sectionStyling?.width ? classes.menuLinksIcons : ''} ${classes.linkPanel}`}
                href={paneltitle?.href}
            >
                <div className={classes.mainContainer}>

                    <ResponsiveImage
                        className={classes.image}
                        path={panelItem?.image?.url}
                        alt={panelItem?.image?.description || 'Item Image'}
                        dimensions={dimensions?.width ? dimensions : panelItem?.image?.dimension}
                    />

                    {paneltitle?.title && (<div className={`${classes.panelItem} nup-v3-panel-item`}> {replaceSpecialChar(paneltitle.title, true)} </div>) }
                </div>
            </LinkOrATag>
        </>

    );
};

NupV3Panel.propTypes = {
    panelItem: shape({
        title: string,
        image: shape({
            url: string,
            content_type: string,
        }),
    }),
    styling: shape({
        presentation_style: string,
        title_font_color: shape({
            color: string,
        }),
    }),
    sectionStyling: shape({
        width: string,
    }),
    handleCallback: func,
    dimensions: shape({
        width: string,
        height: string,
    }),
};
NupV3Panel.defaultProps = {
    panelItem: {},
    styling: {},
    sectionStyling: {},
    handleCallback: () => {},
    dimensions: {},
};

export default (NupV3Panel);
