/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useUIDSeed } from 'react-uid';
import {
    func,
    object,
    shape, string,
} from 'prop-types';
import NupV3Panel from './NupV3Panel';
import NupV2Header from '../NupV2/NupV2Header';
import cleanText from '../../../helpers/contentstack/cleanText';
import NupV3Floral from './NupV3Floral';

const themes = {
    mobilePresentation: {
        grid: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            margin: '0 auto',
        },
        scrollbar: {
            gap: '1.7rem',
        },
    },

};

const useStyles = makeStyles((theme) => ({

    gridContainer: ({ presentationStyle, nupIndex }) => ({
        gap: '2.9rem',
        display: 'grid',
        gridTemplateColumns: `repeat(${nupIndex}, 1fr)`,
        [theme.breakpoints.down(1024)]: {
            gap: '1.9rem',
        },
        [theme.breakpoints.down(767)]: {
            display: themes.mobilePresentation[presentationStyle]?.display || '',
            gap: themes.mobilePresentation[presentationStyle]?.gap || '',
        },
    }),
    mainHeader: {
        width: '100%',
        display: 'flex',
    },
    header: {
        textAlign: 'center',
        width: '100%',
        margin: '0 0 8px',
        lineHeight: '.7em',
        letterSpacing: '.4px',
        fontSize: '1.4em',
        fontWeight: '300',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& h1,h2,h3,h4,h5,h6,p': {
            margin: '.75em 0 .5em',
            fontSize: 'inherit',
            fontWeight: 400,
        },
        '& img': {
            height: '30px',
            width: '30px',
            margin: '.75em 10px .5em',
        },
    },
    heading_paragraph_wrapper: {
        width: '100%',
        marginTop: '15px',
        marginBottom: '20px',
    },
    heading_paragraph: {
        margin: '0 auto',
        textAlign: 'center',
        width: '80%',
        lineHeight: '1.5em',
        letterSpacing: '.4px',
        fontSize: '1.4em',
        '& h1,h2,h3,h4,h5,h6,p': {
            margin: '0px 0 15px 0',
            fontSize: 'inherit',
            fontWeight: 'inherit',
        },
    },
    mainContainer: {
        margin: '0 auto',
        width: '96%',
        overflowX: 'auto',
        maxWidth: '1335px',
        display: 'flex',
        padding: '10px',
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            backgroundColor: 'rgba(156, 156, 156, 1)',  // scroll bar color
        },
        '&::-webkit-scrollbar': {  /* Works on scrollbar for Chrome, Safari and Opera */
            height: '3px',
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: '10px',
            backgroundColor: 'transparent',
        },
        scrollbarColor: 'rgba(213, 213, 213, 1) transparent',
        [theme.breakpoints.down(1440)]: {
            width: 'auto',
        },
        [theme.breakpoints.down(769)]: {
            display: 'flex',
            flexWrap: 'nowrap',
        },
        [theme.breakpoints.down(768)]: {
            margin: '10px',
        },
    },
    nupHeading: {
        display: 'block',
        width: '100%',
        maxWidth: '1400px',
        margin: '0 auto',
        fontSize: '2.8125rem',
        [theme.breakpoints.down(1440)]: {
            paddingLeft: '0.75rem',
        },
    },
    containerFloral: {
        [theme.breakpoints.down(768)]: {
            margin: '10px 0px 0px !important',
            padding: '10px 0px 0px !important',
        },
    },
}));

const NupV3 = ({
    data, sectionStyling, handleCallback, dimensions,
}) => {
    if (!data || Object.keys(data.reference?.[0]).length === 0) return null;
    const itemsList = data?.reference?.[0].entries || [];
    const styling = data?.reference?.[0].styling;
    const isFloralStyle = styling?.presentation_style === 'floral';
    const presentationStyle = styling?.mobile_presentation;
    const nupIndex = itemsList.length || 0;
    const classes = useStyles({ presentationStyle, nupIndex });
    const heading = data.reference[0];
    const seed = useUIDSeed();

    return (
        <>
            {(heading.header_section?.heading || cleanText(heading.header_section?.heading_rich) || heading.header_section?.copy_rich || heading.header_section?.cta_image || heading.header_section?.add_passport_module) && (
                <div className={classes.nupHeading}>
                    <NupV2Header
                        headerSection={heading.header_section}
                        headingStyling={heading.header_styling}
                        showPassportEyebrow={heading.show_passport_eyebrow}
                        eyebrowCopy={heading.eyebrow_copy}
                        variantStyle={heading.presentation_style}
                        entry={heading.presentation_style}
                    />
                </div>
            )}
            <div container className={`${classes.mainContainer} ${isFloralStyle ? classes.containerFloral : ''} nup-v3-container`}>
                {isFloralStyle
                    ? (
                        <NupV3Floral
                            handleCallback={handleCallback}
                            itemsList={itemsList}
                            styling={styling}
                        />
                    )
                    : (
                        <div className={`${classes.gridContainer} nup-v3-grid-container`}>
                            {itemsList?.map((Data) => (
                                <NupV3Panel handleCallback={handleCallback} sectionStyling={sectionStyling} key={seed(Data)} panelItem={Data} styling={styling} dimensions={dimensions} />
                            ))}
                        </div>
                    )}
            </div>
        </>

    );
};

NupV3.propTypes = {
    data: shape({
        title: string,
    }),
    sectionStyling: shape({
        width: string,
    }),
    handleCallback: func,
    dimensions: object,
};
NupV3.defaultProps = {
    data: {},
    sectionStyling: {},
    handleCallback: () => {},
    dimensions: {},
};

export default (NupV3);
