/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    array, shape, string, func,
} from 'prop-types';
import {  useLocation } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import ResponsiveImage from '../../GraphqlComponents/GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import LinkOrATag from '../../GraphqlComponents/GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import replaceSpecialChar from '../../../helpers/replaceSpecialChar';
import parseFontFamilyExtension from '../../../helpers/contentstack/parseFontFamilyExtension';
import useSSRMediaQuery from '../../../helpers/hooks/useSSRMediaQuery';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        margin: '0 auto',
        flexWrap: 'nowrap',
    },
    image: {
        height: 'auto',
    },
    defaultBG: {
        height: '110px',
        width: '110px',
        background: '#F3E7F4',
        borderRadius: '50%',
        [theme.breakpoints.down(600)]: {
            height: '100px',
            width: '100px',
        },
    },
    panelItem: {
        textDecoration: 'none',
        color: '#D5D5D5',
        textAlign: 'center',
        fontFamily: 'Crimson, Garamond, Times, serif',
        fontSize: '16px',
        lineHeight: '26px',
        textWrap: 'balance',
        paddingBottom: '10px',
        letterSpacing: '0.5px',
        marginTop: '12px',
        maxWidth: '110px',
        [theme.breakpoints.down(600)]: {
            fontSize: '14px',
            lineHeight: '20px',
            marginTop: '8px',
            width: '100px',
        },
    },
    linkPanel: {
        textDecoration: 'none',
        marginRight: '20px',
        textAlign: 'center',
        '&:last-child': {
            marginRight: '0px',
        },
        [theme.breakpoints.down(600)]: {
            marginRight: '12px',
        },
    },
    activeSilo: {
        border: '4px solid #201648',
    },
    activeSiloTitle: {
        fontWeight: 700,
    },

}));

const NupV3Floral = ({
    itemsList, styling, handleCallback,
}) => {
    const location = useLocation();
    const isDesktopDevice = useMediaQuery('(min-width: 601px)');
    const deviceType = useSSRMediaQuery();

    const classes = useStyles();

    const defaultDimensions = {
        width: isDesktopDevice && deviceType !== 'mobile' ? '110px' : '100px',
        height: isDesktopDevice && deviceType !== 'mobile' ? '110px' : '100px',
    };

    return (
        <div className={classes.container}>
            { itemsList?.map((item) => {
                const title =  item?.title || item?.linking?.link?.title;
                return (
                    <LinkOrATag
                        isTrackEventEnabled
                        trackingEventAction={item?.linking?.tracking_event_action || ''}
                        trackingEventCategory={item?.linking?.tracking_event_category || ''}
                        trackingEventLabel={item?.linking?.tracking_event_label || ''}
                        handleCallback={handleCallback}
                        className={`${classes.linkPanel}`}
                        href={item?.linking?.link?.href}
                    >
                        <div className={classes.mainContainer}>
                            {item?.image?.url
                                ? (
                                    <ResponsiveImage
                                        className={`${location?.pathname === item?.linking?.link?.href ? classes.activeSilo : ''} ${classes.image} ${classes.defaultBG} `}
                                        path={item?.image?.url}
                                        alt={item?.image?.description || 'Item Image'}
                                        dimensions={item?.image?.dimension?.width ? item?.image?.dimension : defaultDimensions}
                                    />
                                ) : (
                                    <div
                                        className={`${location?.pathname === item?.linking?.link?.href ? classes.activeSilo : ''} ${classes.defaultBG} `}
                                    />
                                )}

                            {title && (
                                <div
                                    className={`${location?.pathname === item?.linking?.link?.href ? classes.activeSiloTitle : ''} ${classes.panelItem}`}
                                    style={{
                                        color: styling?.title_font_color?.color,
                                        fontFamily: `${parseFontFamilyExtension(styling.title_font)}`,
                                    }}
                                > {replaceSpecialChar(title, true)}
                                </div>
                            ) }
                        </div>
                    </LinkOrATag>
                );
            })}
        </div>
    );
};

NupV3Floral.propTypes = {
    styling: shape({
        presentation_style: string,
        title_font_color: shape({
            color: string,
        }),
    }),
    handleCallback: func,
    itemsList: array.isRequired,
};
NupV3Floral.defaultProps = {
    styling: {},
    handleCallback: () => {},

};

export default NupV3Floral;
